import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static classes = ["hide"];
    declare readonly hasHideClass: boolean;
    declare readonly hideClass: string;

    static targets = ["fieldset"];
    declare readonly hasFieldsetTarget: boolean;
    declare readonly fieldsetTarget: HTMLFieldSetElement;

    get fieldsetElement(): HTMLFieldSetElement {
        if (this.hasFieldsetTarget) {
            return this.fieldsetTarget;
        }

        if (this.element instanceof HTMLFieldSetElement) {
            return this.element;
        }

        throw new Error(
            "No compatible fieldset element found: no fieldset target is set, and the controller element is not a fieldset",
        );
    }

    connect(): void {
        if (this.fieldsetElement.disabled) {
            this.disable();
        } else {
            this.enable();
        }
    }

    enable() {
        const fieldset = this.fieldsetElement;
        if (this.hasHideClass) {
            fieldset.classList.remove(this.hideClass);
        }
        fieldset.disabled = false;
    }

    disable() {
        const fieldset = this.fieldsetElement;
        if (this.hasHideClass) {
            fieldset.classList.add(this.hideClass);
        }
        fieldset.disabled = true;
    }
}
