import { Controller } from "@hotwired/stimulus";

// validates that an input value is repeated into a different input (eg. password and
// password confirmation)
export default class extends Controller {
    static targets = ["source", "target"];
    declare readonly sourceTarget: HTMLInputElement;
    declare readonly targetTargets: HTMLInputElement[];

    static values = {
        errorMessage: String,
    };
    declare readonly errorMessageValue: string;

    validate(): void {
        this.targetTargets.forEach((t) => t.setCustomValidity(""));

        const value = this.sourceTarget.value;
        const invalidTarget = this.targetTargets.find((t) => t.value !== value);
        if (invalidTarget) {
            invalidTarget.setCustomValidity(this.errorMessageValue);
        }
    }
}
