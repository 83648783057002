import { Controller } from "@hotwired/stimulus";

// state => detailRequired
type DetailRequiredMap = Map<string, boolean>;

export default class extends Controller {
    static classes = ["required"];
    declare readonly requiredClass: string;

    static targets = ["stateControl", "detailControl"];
    declare readonly stateControlTarget: HTMLSelectElement;
    declare readonly detailControlTarget: HTMLTextAreaElement;

    static values = {
        detailRequiredMap: Object,
    };
    declare readonly detailRequiredMapValue: object;

    get parsedDetailRequiredMap(): DetailRequiredMap {
        const drm = this.detailRequiredMapValue;

        return Object.entries(drm).reduce((carry, [state, detailRequired]) => {
            if (typeof state === "string" && typeof detailRequired === "boolean") {
                carry.set(state, detailRequired);
            }

            return carry;
        }, new Map());
    }

    changeDetailRequired(): void {
        const drm = this.parsedDetailRequiredMap;
        const detailControl = this.detailControlTarget;
        const stateControl = this.stateControlTarget;

        if (drm.has(stateControl.value)) {
            detailControl.required = drm.get(stateControl.value) as boolean;
        }

        // ensure labels provide some feedback on the control being required
        detailControl.labels.forEach((label) => {
            label.classList[detailControl.required ? "add" : "remove"](
                this.requiredClass,
            );
        });
    }
}
