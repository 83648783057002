import { Controller } from "@hotwired/stimulus";

interface CheckboxState {
    checked: boolean;
    indeterminate: boolean;
}

function isCheckbox(obj: any): obj is HTMLInputElement {
    return obj instanceof HTMLInputElement && obj.type === "checkbox";
}

export default class extends Controller {
    static targets = ["aggregateCheckbox", "childCheckbox"];
    // When user interaction happens on an aggregate checkbox, every child checkbox
    // changes their checked state based on the aggregate checkbox.
    // When user interaction happens on a child checkbox, every aggregate checkbox
    // may change their state based on the states of every child checkbox (checked when
    // every child is checked, unchecked when no child is checked, indeterminate
    // otherwise).
    declare readonly aggregateCheckboxTargets: HTMLInputElement[];
    declare readonly childCheckboxTargets: HTMLInputElement[];

    connect() {
        this.updateAggregate();
    }

    // meant to only be invoked by aggregate checkboxes
    multiChange(event: Event) {
        if (!isCheckbox(event.target)) {
            throw new Error('multiChange must be dispatched on inputs type="checkbox"');
        }

        const checked = event.target.checked;

        // change every child checkbox
        this.childCheckboxTargets.forEach((child) => (child.checked = checked));

        // change every aggregate checkbox
        this.aggregateCheckboxTargets.forEach(
            (aggregate) => (aggregate.checked = checked),
        );
    }

    updateAggregate() {
        const childs = this.childCheckboxTargets;
        const childCount = childs.length;
        const checkedCount = childs.reduce<number>(
            (count, child) => (child.checked ? count + 1 : count),
            0,
        );

        let state: CheckboxState = {
            checked: false,
            indeterminate: true,
        };
        if (checkedCount === 0) {
            state = {
                checked: false,
                indeterminate: false,
            };
        } else if (checkedCount === childCount) {
            state = {
                checked: true,
                indeterminate: false,
            };
        }

        this.aggregateCheckboxTargets.forEach((checkbox) => {
            checkbox.checked = state.checked;
            checkbox.indeterminate = state.indeterminate;
        });
    }
}
