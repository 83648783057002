import { Config } from "datatables.net";
import BaseTable from "./base_table";
import { isCustomEvent, stringReplaceWithMap } from "../../helpers/misc_utils";
import { defaultNumberFormatRenderer } from "../../helpers/datatables-net/renderers";
import { es } from "../../helpers/datatables-net/language";

const numberFormatRenderer = defaultNumberFormatRenderer;

interface DetalleCompra {
    id: number;
    repuestoId: number | null;
    numero: number;
    productoId: string;
    productoNombre: string;
    cantidad: number;
}

export default class extends BaseTable {
    static values = {
        ...BaseTable.values,

        dataUrlTemplate: String,
        dataUrlTemplateIdToken: { type: String, default: "__ID__" },

        actionsTemplate: String,
        actionsTemplateIdToken: { type: String, default: "__ID__" },
        actionsTemplateAmountToken: { type: String, default: "__AMOUNT__" },
    };
    declare readonly dataUrlTemplateValue: string;
    declare readonly dataUrlTemplateIdTokenValue: string;
    declare readonly actionsTemplateValue: string;
    declare readonly actionsTemplateIdTokenValue: string;
    declare readonly actionsTemplateAmountTokenValue: string;
    declare readonly updateProductUrlValue: string;
    declare readonly updateProductButtonTemplateValue: string;

    async fetch(event: Event) {
        if (isCustomEvent(event)) {
            const data = event.detail;
            if (data && "id" in data && typeof data.id === "number") {
                this.$dtApi?.ajax
                    .url(
                        this.dataUrlTemplateValue.replace(
                            this.dataUrlTemplateIdTokenValue,
                            data.id,
                        ),
                    )
                    .load()
                    .draw();
            }
        }
    }

    clear() {
        this.$dtApi?.clear().draw();
    }

    protected getTableConfig(): Config {
        return {
            language: es,
            processing: true,
            columns: [
                {
                    data: "id",
                    title: "ID",
                    visible: false,
                },
                {
                    data: "repuestoId",
                    title: "Repuesto ID",
                    visible: false,
                },
                {
                    data: "numero",
                    title: "Linea N°",
                },
                {
                    data: "productoId",
                    title: "Codigo producto",
                },
                {
                    data: "productoNombre",
                    title: "Nombre producto",
                },
                {
                    data: "cantidad",
                    title: "Cantidad",
                },
                {
                    data: "precioCosto",
                    title: "Precio Costo",
                    render: numberFormatRenderer,
                },
                {
                    data: "utilidad",
                    title: "Utilidad (%)",
                    render: numberFormatRenderer,
                },
                {
                    data: "precioVenta",
                    title: "Precio Venta",
                    render: numberFormatRenderer,
                },
                {
                    data: null,
                    title: `Acciones`,
                    render: (data: DetalleCompra) => {
                        if (data.repuestoId === null) {
                            return "Producto no registrado localmente.";
                        }

                        return stringReplaceWithMap(this.actionsTemplateValue, {
                            [this.actionsTemplateIdTokenValue]:
                                data.repuestoId.toString(),
                            [this.actionsTemplateAmountTokenValue]:
                                data.cantidad.toString(),
                        });
                    },
                },
            ],
        };
    }
}
