import FormController from "../form_controller";
import {
    ChainedRemoteFetch,
    FetchRemoteDataContext,
} from "../../helpers/stimulus/chained_remote_fetch";
import { escapeRegExp } from "../../helpers/misc_utils";

export default class extends FormController {
    static targets = [
        ...FormController.targets,
        "province",
        "canton",
        "messagesContainer",
    ];
    declare readonly provinceTarget: HTMLSelectElement;
    declare readonly cantonTarget: HTMLSelectElement;
    declare readonly messagesContainerTarget: HTMLElement;

    static values = {
        ...FormController.values,

        provincesDataUrl: String,

        cantonsDataTemplateUrl: String,

        provinceReplacementToken: String,
    };
    declare readonly provincesDataUrlValue: string;
    declare readonly cantonsDataTemplateUrlValue: string;
    declare readonly provinceReplacementTokenValue: string;

    declare chaninedRemoteFetch: ChainedRemoteFetch;

    get locationTargets(): HTMLSelectElement[] {
        return [this.provinceTarget, this.cantonTarget];
    }

    connect(): void {
        this.chaninedRemoteFetch = new ChainedRemoteFetch(
            this,
            () => this.locationTargets,
            (target) => this.resolveDataUrl(target),
            (target) => this.resolveDataDetail(target),
        );

        // ensure provinceTarget fetches data right away
        const provinceTarget = this.provinceTarget;

        // ensure select targets and controllers are loaded before dispatching the event
        window.requestAnimationFrame(() => {
            this.dispatch("fetchRemoteData", {
                target: provinceTarget,
                detail: this.resolveDataDetail({
                    url: this.provincesDataUrlValue,
                    target: provinceTarget,
                }),
            });
        });
    }

    syncLocation(event: Event) {
        this.chaninedRemoteFetch.sync(event);
    }

    resetLocation() {
        this.chaninedRemoteFetch.reset();
    }

    displayMessage(event: CustomEvent<{ message: string | HTMLElement }>) {
        this.renderMessage(event.detail.message);
    }

    private resolveDataUrl(target: HTMLElement) {
        const provinceTarget = this.provinceTarget;
        const cantonTarget = this.cantonTarget;

        const UrlMap = new Map<HTMLElement, string>([
            [provinceTarget, this.provincesDataUrlValue],
            [cantonTarget, this.cantonsDataTemplateUrlValue],
        ]);

        const url = UrlMap.get(target);
        if (!url) {
            throw new Error("Invalid target.");
        }

        const replacementMap = new Map<string, string>([
            [escapeRegExp(this.provinceReplacementTokenValue), provinceTarget.value],
        ]);

        const regex = new RegExp(Array.from(replacementMap.keys()).join("|"), "g");

        return url.replace(regex, (match) => {
            if (replacementMap.has(match)) {
                return replacementMap.get(match) as string;
            }

            return "";
        });
    }

    private resolveDataDetail(context: FetchRemoteDataContext) {
        return {
            url: context.url,
            schema: {
                value: "id",
                label: "name",
            },
        };
    }

    protected processingSubmit(event: SubmitEvent): () => void {
        const parentDone = super.processingSubmit(event);
        this.renderMessage("");

        return () => {
            parentDone();
        };
    }

    private renderMessage(message: string | HTMLElement) {
        if (typeof message === "string") {
            this.messagesContainerTarget.textContent = message;
        } else {
            this.messagesContainerTarget.innerHTML = "";
            this.messagesContainerTarget.appendChild(message);
        }
    }
}
