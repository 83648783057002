import { Controller } from "@hotwired/stimulus";

export interface EventNames {
    clear: string;
    fetchRemoteData: string;
    reset: string;
}

export interface FetchRemoteDataContext {
    url: string;
    target: HTMLElement;
}

export class ChainedRemoteFetch {
    constructor(
        private controller: Controller,
        private targetsAccessor: () => HTMLElement[],
        private dataUrlResolver: (target: HTMLElement) => string,
        private dataDetailResolver: (context: FetchRemoteDataContext) => any,

        private eventNames: EventNames = {
            clear: "clear",
            fetchRemoteData: "fetchRemoteData",
            reset: "reset",
        },
    ) {}

    sync({ target: referenceTarget }: Event) {
        const targets = this.targetsAccessor();
        const targetIndex = targets.findIndex((target) => referenceTarget === target);
        if (targetIndex === -1) {
            // dispatched event on invalid target
            return;
        }

        // Our targets array order matters.
        // This means that every control's values depends on the previous control.
        // So, let's clear every control after the one we just found.
        targets.slice(targetIndex + 1).forEach((target) => {
            this.controller.dispatch(this.eventNames.clear, { target });
        });

        // have the next control, if it exists, load it's dependent data
        const nextTarget = targets[targetIndex + 1];
        if (nextTarget) {
            const url = this.dataUrlResolver(nextTarget);

            this.controller.dispatch(this.eventNames.fetchRemoteData, {
                target: nextTarget,
                detail: this.dataDetailResolver({
                    url,
                    target: nextTarget,
                }),
            });
        }
    }

    reset() {
        this.targetsAccessor().forEach((target) => {
            this.controller.dispatch(this.eventNames.reset, { target });
        });
    }
}
