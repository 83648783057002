import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLElement | HTMLInputElement> {
    static targets = ["input"];

    // targets
    declare readonly hasInputTarget: boolean;
    declare readonly inputTarget: HTMLInputElement;

    get inputElement() {
        if (this.hasInputTarget) {
            return this.inputTarget;
        }

        if (this.element instanceof HTMLInputElement) {
            return this.element;
        }

        throw new Error(
            "No valid input element found to attach to." +
                " No input target found" +
                " and this.element is not an HTMLInputElement.",
        );
    }

    clear() {
        this.inputElement.value = "";
    }
}
