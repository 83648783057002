import { ActionEvent, Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { parseUrl } from "../helpers/fetch_utils";

interface SelectDataSchema {
    label: string;
    value: string;
    dataset?: Record<string, string>;
}

interface FetchDataParams {
    url: URL;
    schema: SelectDataSchema;
    query?: URLSearchParams;
}

interface CustomChangeParams {
    prefix?: string;
    detail?: any & { value: any };
}

export default class extends Controller<HTMLElement | HTMLSelectElement> {
    static targets = ["select"];
    declare readonly hasSelectTarget: boolean;
    declare readonly selectTarget: HTMLSelectElement;

    static values = {
        tsAllowEmptyOption: { type: Boolean, default: true },
    };
    declare readonly tsAllowEmptyOptionValue: boolean;

    // instance
    tomSelect: TomSelect | null = null;

    get selectElement(): HTMLSelectElement {
        if (this.hasSelectTarget) {
            return this.selectTarget;
        }

        if (this.element instanceof HTMLSelectElement) {
            return this.element;
        }

        throw new Error(
            "No valid select element found to attach to." +
                " No select target found" +
                " and this.element is not an HTMLSelectElement.",
        );
    }

    get emptyOption(): HTMLOptionElement | null {
        return (
            Array.from(this.selectElement.options).find((o) => o.value === "") || null
        );
    }

    connect() {
        this.tomSelect = new TomSelect(this.selectElement, {
            maxItems: 1,
            allowEmptyOption: this.tsAllowEmptyOptionValue,
            // docs say to use null for maxOptions to allow unlimited options, but types
            // allow undefined instead of null... undefined seems to works, tho.
            maxOptions: undefined,
            render: {
                option_create: (data: any, escape: (s: string) => string) => {
                    return `\
                        <div class="create">
                            Agregar <strong> ${escape(data.input)}</strong> &hellip;
                        </div>
                    `;
                },
                no_results: () => {
                    return `\
                        <div class="no-results">No se encontraron resultados</div>
                    `;
                },
            },
        });
    }

    disconnect(): void {
        this.tomSelect?.destroy();
        this.tomSelect = null;
    }

    fetchData(event: CustomEvent) {
        const params = this._parseFetchDataParams(event.detail);

        if (!params) {
            return;
        }

        let url = `${params.url.origin}${params.url.pathname}`;
        if (params.query) {
            url += `?${params.query}`;
        }

        const emptyOption = this.emptyOption;
        const selectElement = this.selectElement;
        const tomSelect = this.tomSelect;
        selectElement.options.length = 0;

        const cleanUp = (() => {
            const loadingOption = new Option("Cargando...", "", undefined, true);
            selectElement.options.add(loadingOption);

            return () => {
                loadingOption.remove();
                tomSelect?.sync();
                tomSelect?.setValue("", true);
            };
        })();

        window
            .fetch(url, {
                headers: {
                    Accept: "application/json",
                },
            })
            .then(async (res) => {
                const json = await res.json();

                let data: any[] = [];
                if (typeof json === "object" && Array.isArray(json.data)) {
                    data = json.data;
                } else if (Array.isArray(json)) {
                    data = json;
                }

                if (emptyOption) {
                    selectElement.options.add(emptyOption);
                }
                data.forEach((item) => {
                    selectElement.options.add(
                        new Option(
                            item[params.schema.label],
                            item[params.schema.value],
                        ),
                    );
                });
            })
            .catch((error) => {
                console.log(error);
                selectElement.options.add(
                    new Option("Se presentó un problema obteniendo los datos."),
                );
            })
            .finally(() => {
                cleanUp();
            });
    }

    dispatchCustomChange(event: ActionEvent) {
        const params = this._parseCustomChangeParams(event);

        this.dispatch("change", {
            prefix: params.prefix,
            detail: params.detail,
        });
    }

    /** @deprecated use setToEmpty */
    clear() {
        this.setToEmpty();
    }

    setToEmpty() {
        this.tomSelect?.setValue("", true);
    }

    clearOptions() {
        this.setToEmpty();
        this.tomSelect?.clearOptions();
    }

    _parseFetchDataParams(params: any): FetchDataParams | null {
        if (typeof params === "object" && params) {
            let url: undefined | URL;
            if ("url" in params && typeof params.url === "string") {
                try {
                    url = parseUrl(params.url);
                } catch {
                    // pass
                }
            }
            let schema: undefined | SelectDataSchema;
            if (
                "schema" in params &&
                typeof params.schema === "object" &&
                typeof params.schema.label === "string" &&
                typeof params.schema.value === "string"
            ) {
                schema = params.schema;
            }
            if (url && schema) {
                let query: undefined | URLSearchParams;
                if ("query" in params && typeof params.query === "object") {
                    query = new URLSearchParams(params.query);
                }

                return {
                    url,
                    schema,
                    query,
                };
            }
        }

        return null;
    }

    _parseCustomChangeParams(event: ActionEvent): CustomChangeParams {
        const eventParams = event.params;
        if (typeof eventParams === "object" && eventParams !== null) {
            const selectValue = this.selectElement.value;
            function transformValue(value: any): any {
                if (typeof value === "object") {
                    if (Array.isArray(value)) {
                        return value.map((v) => transformValue(v));
                    }

                    return Object.fromEntries(
                        Object.entries(value).map(([p, v]) => [p, transformValue(v)]),
                    );
                }
                if (typeof value === "string" && value.includes("__VALUE__")) {
                    return value.replace(/__VALUE__/g, selectValue);
                }

                return value;
            }

            const transformedParams = Object.entries(eventParams).reduce(
                (transformed, [prop, value]) => {
                    if (prop === "prefix" && typeof value === "string") {
                        return {
                            ...transformed,
                            prefix: value,
                        };
                    }
                    let transformedValue = transformValue(value);

                    return {
                        ...transformed,
                        detail: {
                            ...transformed.detail,
                            [prop]: transformedValue,
                        },
                    };
                },
                {
                    detail: {},
                },
            );

            return transformedParams;
        }

        return {};
    }
}
