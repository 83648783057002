import { Controller, ActionEvent } from "@hotwired/stimulus";
import { DisplayableError, displayError } from "../helpers/errors";
import { parseJsonResponse } from "../helpers/fetch_utils";
import {
    BadParamsError,
    entityRouteActionParams,
    EntityRouteActionParams,
} from "../helpers/stimulus/action_params";
import { dispatchDataUpdated } from "../helpers/stimulus/events";

export default class extends Controller<HTMLElement> {
    async register(event: ActionEvent) {
        const target = event.target as HTMLElement;

        let params: EntityRouteActionParams;
        try {
            params = entityRouteActionParams(event.params);
        } catch (error) {
            let userMessage: string, logMessage: any;
            if (error instanceof BadParamsError) {
                userMessage = error.userMessage;
            } else {
                userMessage = "Se presentó un problema inesperado.";
            }
            logMessage = error;
            this._displayError(userMessage, logMessage);
            return;
        }

        const cleanUp = (() => {
            const originalTargetHTML = target.innerHTML;
            target.textContent = "Cambiando...";
            let reEnableTarget = () => {}; // noop
            if ("disabled" in target) {
                target.disabled = true;
                reEnableTarget = () => {
                    target.disabled = false;
                };
            }

            return () => {
                reEnableTarget();
                target.innerHTML = originalTargetHTML;
            };
        })();

        let res;
        try {
            res = await window.fetch(params.actionUrl, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                body: new URLSearchParams({
                    id: params.entityId.toString(),
                }),
            });
        } catch (error) {
            this._displayError(
                "Se presentó un problema solicitando registrar en Spyka.",
                error,
            );
            return;
        } finally {
            cleanUp();
        }
        let data;
        try {
            data = await parseJsonResponse(res);
        } catch (error) {
            let userMessage;
            if (error instanceof DisplayableError) {
                userMessage = error.userMessage;
            } else {
                userMessage = "Se presentó un problema inesperado.";
            }
            this._displayError(userMessage, error);
        }

        dispatchDataUpdated(this, {
            entityId: params.entityId,
            prop: "IsInSpyka",
            value: true,
        });
    }

    private _displayError = displayError.bind(this);
}
