import { Controller } from "@hotwired/stimulus";

interface HTMLElementWithValue extends HTMLInputElement {
    value: string;
}

export default class extends Controller<HTMLElementWithValue> {
    static values = {
        url: String,
    };

    declare readonly urlValue: string;

    reqrel() {
        const value = this.element.value;

        fetch(this.urlValue + "/" + value).then(() => {
            this.element.outerHTML = "Recargando página...";
            setTimeout(() => window.location.reload(), 1500);
        });
    }
}
