import { ActionEvent, Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static classes = ["active"];
    declare readonly activeClass: string;

    static targets = ["navElement"];
    declare readonly navElementTargets: HTMLElement[];

    updateActive(event: ActionEvent) {
        const target = event.target;
        if (!(target instanceof HTMLAnchorElement)) {
            return;
        }

        let url: URL;
        try {
            url = new URL(target.href);
        } catch {
            return;
        }

        this.navElementTargets.forEach((navElement) => {
            // kinda ugly...
            const anchor = navElement.querySelector("a");
            if (anchor) {
                const anchorUrl = new URL(anchor.href);
                if (url.hash === anchorUrl.hash) {
                    navElement.classList.add(this.activeClass);
                } else {
                    navElement.classList.remove(this.activeClass);
                }
            }
        });
    }
}
