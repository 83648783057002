import { Controller, ActionEvent } from "@hotwired/stimulus";
import DataTable, { Api, Config, ConfigColumns } from "datatables.net-dt";
import "datatables.net-responsive-dt";
import debounce from "lodash.debounce";
import {
    makeActionsRenderer,
    makeDateTimeRenderer,
    makeImageRenderer,
    makeIsActiveRenderer,
    makeIsInSpykaRenderer,
} from "../../helpers/datatables-net/renderers";
import { DataUpdatedDetail } from "../../helpers/stimulus/events";

import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-responsive-dt/css/responsive.datatables.css";

type RendererMaker = (options?: {}) => ConfigColumns["render"];

const renderers: Record<string, RendererMaker> = {
    imageRenderer: makeImageRenderer,
    text: DataTable.render.text,
    isActiveRenderer: makeIsActiveRenderer,
    isInSpykaRenderer: makeIsInSpykaRenderer,
    actions: makeActionsRenderer,
    datetime: makeDateTimeRenderer,
};

export default class extends Controller<HTMLElement | HTMLTableElement> {
    static targets = ["table", "filter", "search"];
    static values = {
        configTable: Object,
    };

    // targets
    declare readonly hasTableTarget: boolean;
    declare readonly tableTarget: HTMLTableElement;
    declare readonly filterTargets: Array<HTMLInputElement | HTMLSelectElement>;
    declare readonly searchTargets: HTMLInputElement[];

    // values
    declare readonly configTableValue: Config;

    // instance
    tableInitialized = false;
    $dtApi?: Api<any>;

    get table(): HTMLTableElement {
        if (this.hasTableTarget) {
            return this.tableTarget;
        }
        if (!(this.element instanceof HTMLTableElement)) {
            throw new Error(
                "No valid table element found to attach to." +
                    " No table target found" +
                    " and this.element is not an HTMLTableElement.",
            );
        }

        return this.element;
    }

    connect(): void {
        this.tableInitialized = false;
        const table = this.table;

        if (DataTable.isDataTable(table)) {
            this.tableInitialized = true;
        }
        if (!this.tableInitialized) {
            const tableConfig = structuredClone(this.configTableValue);
            if (tableConfig.columns) {
                const columns = tableConfig.columns?.map((columnSettings) => {
                    const transformed = structuredClone(columnSettings);
                    let renderer;
                    if (typeof transformed.render === "string") {
                        if (transformed.render in renderers) {
                            renderer = renderers[transformed.render]();
                        }
                    } else if (typeof transformed.render === "object") {
                        const renderConfig = transformed.render;
                        let maker;
                        if (
                            "name" in renderConfig &&
                            typeof renderConfig.name === "string"
                        ) {
                            maker = renderers[renderConfig.name];
                        }
                        if (maker) {
                            let options = {};
                            if (
                                "options" in renderConfig &&
                                renderConfig.options &&
                                typeof renderConfig.options === "object"
                            ) {
                                options = renderConfig.options;
                            }
                            renderer = maker(options);
                        }
                    }

                    if (renderer) {
                        transformed.render = renderer;
                    } else {
                        delete transformed.render;
                    }

                    return transformed;
                });
                tableConfig.columns = columns;
            }

            this.$dtApi = new DataTable(table, tableConfig);

            if (tableConfig.ajax) {
                this.$dtApi.on("preXhr", ($event, settings, data) => {
                    if (typeof data === "object" && data !== null) {
                        // move up...?
                        interface Filter {
                            name: string;
                            value: string;
                        }

                        data.filters = this.filterTargets.reduce<Filter[]>(
                            (filters, filterTarget) => {
                                if (filterTarget.value) {
                                    return [
                                        ...filters,
                                        {
                                            name: filterTarget.name,
                                            value: filterTarget.value,
                                        },
                                    ];
                                }
                                return filters;
                            },
                            [],
                        );

                        interface SearchTerm {
                            name: string;
                            term: string;
                        }

                        data.searchTerms = this.searchTargets.reduce<SearchTerm[]>(
                            (searchTerms, searchTarget) => {
                                if (searchTarget.value) {
                                    return [
                                        ...searchTerms,
                                        {
                                            name: searchTarget.name,
                                            term: searchTarget.value,
                                        },
                                    ];
                                }

                                return searchTerms;
                            },
                            [],
                        );
                    }
                });
            }
        }

        this.refresh = debounce(this.refresh, 350, { trailing: true });
    }

    disconnect(): void {
        if (this.tableInitialized) {
            this.$dtApi?.destroy();
        }
    }

    updateData(event: CustomEvent) {
        const data = this._parseUpdateData(event.detail);
        if (data) {
            const row = this.$dtApi?.row((index: number, rowData: any, node: any) => {
                return rowData && "id" in rowData && rowData.id === data.entityId;
            });
            if (row?.any()) {
                const rowData = row.data() as Record<string, any>;
                rowData[data.prop] = data.value;
                row.data(rowData).draw(false);
            }
        }
    }

    /** @todo rename to serverSideRefresh */
    refresh(event: ActionEvent) {
        const paging = event.params.resetPaging || false;
        this.$dtApi?.draw(paging);
    }

    ajaxReload() {
        this.$dtApi?.ajax.reload(undefined, false);
    }

    private _parseUpdateData(detail: any): DataUpdatedDetail | null {
        if (!detail) {
            return null;
        }

        const { entityId, prop, value } = detail;
        if (typeof entityId !== "number") {
            return null;
        }
        if (typeof prop !== "string") {
            return null;
        }

        return {
            entityId,
            prop,
            value,
        };
    }
}
