export const breakpoints = {
    xsmall: "xsmall",
    small: "small",
    medium: "medium",
    large: "large",
} as const;

export function getCssBreakpoint() {
    return window.getComputedStyle(document.body, ":before").content.replace(/\"/g, "");
}

// from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
export function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function isCustomEvent(e: Event): e is CustomEvent {
    return "detail" in e;
}

export function stringReplaceWithMap(s: string, map: Record<string, string>): string {
    const replacementMap = new Map<string, string>(
        Object.entries(map).map(([key, value]) => [escapeRegExp(key), value]),
    );

    const regex = new RegExp(Array.from(replacementMap.keys()).join("|"), "g");

    return s.replace(regex, (match) => {
        return replacementMap.get(match) || "";
    });
}
