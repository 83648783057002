import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["province", "canton", "district", "neighborhood"];
    declare readonly provinceTarget: HTMLSelectElement;
    declare readonly cantonTarget: HTMLSelectElement;
    declare readonly districtTarget: HTMLSelectElement;
    declare readonly neighborhoodTarget: HTMLSelectElement;

    static values = {
        provincesDataUrl: String,

        cantonsDataTemplateUrl: String,
        districtsDataTemplateUrl: String,
        neighborhoodsDataTemplateUrl: String,

        provinceReplacementToken: String,
        cantonReplacementToken: String,
        districtReplacementToken: String,
    };
    declare readonly provincesDataUrlValue: string;
    declare readonly cantonsDataTemplateUrlValue: string;
    declare readonly districtsDataTemplateUrlValue: string;
    declare readonly neighborhoodsDataTemplateUrlValue: string;
    declare readonly provinceReplacementTokenValue: string;
    declare readonly cantonReplacementTokenValue: string;
    declare readonly districtReplacementTokenValue: string;

    get locationTargets() {
        return [
            this.provinceTarget,
            this.cantonTarget,
            this.districtTarget,
            this.neighborhoodTarget,
        ];
    }

    connect() {
        // ensure provinceTarget fetches data right away
        const provinceTarget = this.provinceTarget;

        // ensure select targets and controllers are loaded before dispatching the event
        window.requestAnimationFrame(() => {
            this.dispatch("fetchRemoteData", {
                target: provinceTarget,
                detail: {
                    url: this.provincesDataUrlValue,
                    schema: this.getSchema(provinceTarget),
                },
            });
        });
    }

    sync({ target }: Event) {
        const locationTargets = this.locationTargets;
        const locationTargetIndex = locationTargets.findIndex(
            (locationTarget) => locationTarget === target,
        );
        if (locationTargetIndex === -1) {
            // dispatched event on invalid target
            // this event must be dispatched on a location target (province, canton,
            // district, neighborhood)
            return;
        }

        // for location here, our locationTargets array order matters.
        // This means that every control's values depends on the previous control.
        // So, let's clear every control after the one we just found.
        locationTargets.slice(locationTargetIndex + 1).forEach((locationTarget) => {
            this.dispatch("clear", {
                target: locationTarget,
            });
        });

        // have the next control, if it exists, load it's dependent data
        const nextTarget = locationTargets[locationTargetIndex + 1];
        if (nextTarget) {
            const url = this.getDataUrl(nextTarget);

            this.dispatch("fetchRemoteData", {
                target: nextTarget,
                detail: {
                    url,
                    schema: this.getSchema(nextTarget),
                },
            });
        }
    }

    dispatchReset() {
        this.locationTargets.forEach((locationTarget) => {
            this.dispatch("reset", {
                target: locationTarget,
            });
        });
    }

    private getDataUrl(locationTarget: HTMLSelectElement): string {
        const provinceTarget = this.provinceTarget;
        const cantonTarget = this.cantonTarget;
        const districtTarget = this.districtTarget;
        const neighborhoodTarget = this.neighborhoodTarget;

        const UrlMap = new Map<HTMLSelectElement, string>([
            [provinceTarget, this.provincesDataUrlValue],
            [cantonTarget, this.cantonsDataTemplateUrlValue],
            [districtTarget, this.districtsDataTemplateUrlValue],
            [neighborhoodTarget, this.neighborhoodsDataTemplateUrlValue],
        ]);

        const url = UrlMap.get(locationTarget);
        if (!url) {
            throw new Error("locationTarget parameter is not a valid locationTarget.");
        }

        // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
        function escapeRegExp(string: string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        }

        // build the url from the url templating configured...
        // this is kinda messy...
        const ReplacementMap = new Map<string, string>([
            [escapeRegExp(this.provinceReplacementTokenValue), provinceTarget.value],
            [escapeRegExp(this.cantonReplacementTokenValue), cantonTarget.value],
            [escapeRegExp(this.districtReplacementTokenValue), districtTarget.value],
        ]);

        // make a regex from our replacement tokens
        const regex = new RegExp(Array.from(ReplacementMap.keys()).join("|"), "g");

        // finally, handle the replacement:
        return url.replace(regex, (match) => {
            if (ReplacementMap.has(match)) {
                return ReplacementMap.get(match) as string;
            }

            return "";
        });
    }

    private getSchema(locationTarget: HTMLSelectElement) {
        /** @todo config values for locationTarget schema? */
        return {
            label: "display",
            value: "id",
        };
    }
}
