import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["filter"];

    // targets
    declare readonly filterTargets: Array<HTMLInputElement | HTMLSelectElement>;

    clearFilters() {
        this.filterTargets.forEach((f) => {
            this.dispatch("clear", {
                target: f,
                bubbles: false,
            });
        });
    }
}
