import { ActionEvent, Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { parseUrl } from "../../helpers/fetch_utils";
import "./_styles/search_controller.scss";

interface SelectDataSchema {
    label: string;
    value: string;
    dataset?: Record<string, string>;
}

interface FetchDataParams {
    url: URL;
    schema: SelectDataSchema;
    query?: URLSearchParams;
}

interface CustomChangeParams {
    prefix?: string;
    detail?: any & { value: any };
}

export default class extends Controller<HTMLElement | HTMLInputElement> {
    static targets = ["select"];
    declare readonly hasInputTarget: boolean;
    declare readonly inputTarget: HTMLInputElement;

    // instance
    tomSelect: TomSelect | null = null;

    get inputElement(): HTMLInputElement {
        if (this.hasInputTarget) {
            return this.inputTarget;
        }

        if (this.element instanceof HTMLInputElement) {
            return this.element;
        }

        throw new Error(
            "No valid select element found to attach to." +
                " No select target found" +
                " and this.element is not an HTMLInputElement.",
        );
    }

    connect() {
        // console.log(this.selectElement);
        this.tomSelect = new TomSelect(this.inputElement, {
            maxItems: 1,
            allowEmptyOption: true,
        });
    }

    disconnect(): void {
        this.tomSelect?.destroy();
        this.tomSelect = null;
    }

    fetchData(event: CustomEvent) {
        const params = this._parseFetchDataParams(event.detail);

        if (!params) {
            return;
        }

        let url = `${params.url.origin}${params.url.pathname}`;
        if (params.query) {
            url += `?${params.query}`;
        }

        const selectElement = this.inputElement;
        const tomSelect = this.tomSelect;
    }

    dispatchCustomChange(event: ActionEvent) {
        const params = this._parseCustomChangeParams(event);

        this.dispatch("change", {
            prefix: params.prefix,
            detail: params.detail,
        });
    }

    clear() {
        this.tomSelect?.setValue("", true);
    }

    _parseFetchDataParams(params: any): FetchDataParams | null {
        if (typeof params === "object" && params) {
            let url: undefined | URL;
            if ("url" in params && typeof params.url === "string") {
                try {
                    url = parseUrl(params.url);
                } catch {
                    // pass
                }
            }
            let schema: undefined | SelectDataSchema;
            if (
                "schema" in params &&
                typeof params.schema === "object" &&
                typeof params.schema.label === "string" &&
                typeof params.schema.value === "string"
            ) {
                schema = params.schema;
            }
            if (url && schema) {
                let query: undefined | URLSearchParams;
                if ("query" in params && typeof params.query === "object") {
                    query = new URLSearchParams(params.query);
                }

                return {
                    url,
                    schema,
                    query,
                };
            }
        }

        return null;
    }

    _parseCustomChangeParams(event: ActionEvent): CustomChangeParams {
        const eventParams = event.params;
        if (typeof eventParams === "object" && eventParams !== null) {
            const selectValue = this.inputElement.value;
            function transformValue(value: any): any {
                if (typeof value === "object") {
                    if (Array.isArray(value)) {
                        return value.map((v) => transformValue(v));
                    }

                    return Object.fromEntries(
                        Object.entries(value).map(([p, v]) => [p, transformValue(v)]),
                    );
                }
                if (typeof value === "string" && value.includes("__VALUE__")) {
                    return value.replace(/__VALUE__/g, selectValue);
                }

                return value;
            }

            const transformedParams = Object.entries(eventParams).reduce(
                (transformed, [prop, value]) => {
                    if (prop === "prefix" && typeof value === "string") {
                        return {
                            ...transformed,
                            prefix: value,
                        };
                    }
                    let transformedValue = transformValue(value);

                    return {
                        ...transformed,
                        detail: {
                            ...transformed.detail,
                            [prop]: transformedValue,
                        },
                    };
                },
                {
                    detail: {},
                },
            );

            return transformedParams;
        }

        return {};
    }
}
