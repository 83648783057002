import { Controller } from "@hotwired/stimulus";
import { breakpoints, getCssBreakpoint } from "../helpers/misc_utils";

export default class extends Controller {
    static classes = ["active"];
    declare readonly activeClass: string;

    static targets = ["element", "showElementButton"];
    declare readonly elementTarget: HTMLInputElement;
    declare readonly showElementButtonTarget: HTMLButtonElement;

    #resizeListener: (() => void) | null = null;

    connect(): void {
        if (this.#resizeListener) {
            window.removeEventListener("resize", this.#resizeListener);
        }

        const element = this.elementTarget;
        const showElementButton = this.showElementButtonTarget;
        this.#resizeListener = () => {
            const breakpoint = getCssBreakpoint();
            if (breakpoint === breakpoints.medium || breakpoint === breakpoints.large) {
                element.classList.remove(this.activeClass);
            } else {
                if (showElementButton.classList.contains(this.activeClass)) {
                    element.classList.add(this.activeClass);
                }
            }
        };
        window.addEventListener("resize", this.#resizeListener);
    }

    disconnect(): void {
        if (this.#resizeListener) {
            window.removeEventListener("resize", this.#resizeListener);
            this.#resizeListener = null;
        }
    }

    toggle(): void {
        if (this.showElementButtonTarget.classList.contains(this.activeClass)) {
            // hiding
            const anim = this.elementTarget.animate(
                [
                    {
                        transform: "scaleY(1)",
                        transformOrigin: "top",
                    },
                    {
                        transform: "scaleY(0)",
                        transformOrigin: "top",
                    },
                ],
                200,
            );
            anim.finished.then(() => {
                this.elementTarget.classList.remove(this.activeClass);
            });
        } else {
            // showing
            this.elementTarget.classList.add(this.activeClass);
            this.elementTarget.animate(
                [
                    {
                        transform: "scaleY(0)",
                        transformOrigin: "top",
                    },
                    {
                        transform: "scaleY(1)",
                        transformOrigin: "top",
                    },
                ],
                200,
            );
        }
        this.showElementButtonTarget.classList.toggle(this.activeClass);
    }
}
