import TomSelect from "tom-select";
import type { TomOption, TomSettings } from "tom-select/dist/types/types";
import { SparePart } from "../../interfaces/TuMotorApp";
import SearchController from "./search_controller";

export default class extends SearchController {
    static values = {
        searchUrl: String,
    };
    declare readonly searchUrlValue: string;

    connect(): void {
        const load: TomSettings["load"] = (query, callback) => {
            const search = new URLSearchParams({
                q: query,
            });
            window
                .fetch(`${this.searchUrlValue}?${search.toString()}`)
                .then((res) => res.json())
                .then((data) => callback(data.items as TomOption[], []))
                .catch((err) => callback([], []));
        };
        const render: Partial<TomSettings["render"]> = {
            option: (item, escape) => {
                const classPrefix = "ib-search-item";
                const part = item as SparePart;
                const imgRender = function (src: string, alt: string = ""): string {
                    return `\
                    <img class="${classPrefix}-img" src="${escape(src)}"
                         alt="${escape(alt)}">\
                    `;
                };

                let img = imgRender("data:,", "Imágen no disponible");
                const images = part.images;
                if (images && images[0]) {
                    img = imgRender(images[0].src, images[0].caption);
                }

                return `\
                <div class="row ${classPrefix}">
                    <div class="col-xs-2">${img}</div>
                    <div class="col-xs-10 ${classPrefix}-info">
                        <div class="${classPrefix}-code">
                            ${escape(part.model.name)}
                        </div>
                        <div class="${classPrefix}-name">${escape(part.name)}</div>
                        <div class="${classPrefix}-brand">
                            ${escape(part.sparePartBrand.name)}
                        </div>
                    </div>
                </div>\
                `;
            },
            item: (item, escape) => {
                return `<div>${escape(item.name)}</div>`;
            },
            option_create: (data, escape) => {
                return `<div class="create">Buscar
                    <strong>${escape(data.input)}</strong>&hellip;
                </div>`;
            },
            no_results: () => {
                return '<div class="no-results">No se encontraron repuestos</div>';
            },
        };
        this.tomSelect = new TomSelect(this.inputElement, {
            valueField: "id",
            labelField: "name",
            searchField: ["commercialCode", "name", "sparePartBrand.name"],
            nesting: true,

            // create: true,
            persist: false,

            options: [],
            maxItems: 1,
            maxOptions: 5,

            hidePlaceholder: false,
            shouldLoad: (query: string) => {
                return query.length > 1;
            },
            load,
            render,
        });
        this.tomSelect.on("item_add", (value: string) => {
            if (this.tomSelect === null || !value) {
                return;
            }

            this.tomSelect.disable();
            const id = Number.parseInt(value);

            let detail, newPlaceholder;
            if (Number.isNaN(id)) {
                detail = {
                    route: "search_spare_part",
                    query: {
                        q: value,
                    },
                };
                newPlaceholder = "Buscando repuesto...";
            } else {
                detail = {
                    route: "show_spare_part",
                    params: {
                        id,
                    },
                };
                newPlaceholder = "Mostrando repuesto...";
            }
            this.tomSelect.settings.placeholder = newPlaceholder;
            this.dispatch("redirect", {
                detail,
            });
        });
    }
}
