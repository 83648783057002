import { Controller } from "@hotwired/stimulus";

const template = document.createElement("template");
template.innerHTML = `<div class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title"></h5>
      </div>
      <div class="modal-body"></div>
      <div class="modal-footer">
        <button type="button" class="btn btn-info" data-dismiss="modal">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>`;

function makeModal() {
    return template.content.firstElementChild!.cloneNode(true) as HTMLElement;
}

function makeStimulusModal(controller: Controller, targetName: string = "modal") {
    const modalElement = makeModal();

    const identifier = controller.identifier;
    const schema = controller.application.schema;
    const targetAttribute = schema.targetAttributeForScope(identifier);
    modalElement.setAttribute(targetAttribute, targetName);

    const content = modalElement.querySelector(".modal-content");
    content?.setAttribute(targetAttribute, "content");

    const title = modalElement.querySelector(".modal-title");
    title?.setAttribute(targetAttribute, "title");

    const body = modalElement.querySelector(".modal-body");
    body?.setAttribute(targetAttribute, "body");

    const footer = modalElement.querySelector(".modal-footer");
    footer?.setAttribute(targetAttribute, "footer");

    return modalElement;
}

export { makeModal, makeStimulusModal };
