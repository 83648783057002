import { Controller } from "@hotwired/stimulus";
import { isCustomEvent } from "../helpers/misc_utils";
import { parseJsonResponse } from "../helpers/fetch_utils";
import { DisplayableError, displayError } from "../helpers/errors";

export default class extends Controller {
    static targets = ["purchaseIdInput", "updatePurchaseProductsForm"];
    declare readonly purchaseIdInputTargets: HTMLInputElement[];
    declare readonly updatePurchaseProductsFormTarget: HTMLFormElement;

    updatePurchaseId(event: Event) {
        if (isCustomEvent(event)) {
            const data = event.detail;
            if (data && "id" in data && typeof data.id === "number") {
                this.updatePurchaseIdInputsValue(data.id.toString());
            }
        }
    }

    async updatePurchaseProducts(event: SubmitEvent) {
        event.preventDefault();
        if (
            !confirm(
                "¿Está seguro que desea actualizar los datos de venta de los productos? Este proceso no se puede revertir.",
            )
        ) {
            return;
        }

        const cleanup = (() => {
            const submitter = event.submitter;
            let submitterCleanup = () => {}; // noop
            if (
                submitter &&
                "disabled" in submitter &&
                typeof submitter.disabled === "boolean"
            ) {
                submitter.disabled = true;
                submitterCleanup = () => {
                    submitter.disabled = false;
                };
            }

            return () => {
                submitterCleanup();
            };
        })();

        const form = this.updatePurchaseProductsFormTarget;
        let data;
        try {
            data = await parseJsonResponse(
                await window.fetch(form.action, {
                    method: form.method,
                    body: new FormData(form),
                }),
            );
        } catch (error) {
            let userMessage;
            if (error instanceof DisplayableError) {
                userMessage = error.userMessage;
            } else {
                userMessage = "Se presentó un problema inesperado.";
            }
            displayError(userMessage, error);
            return;
        } finally {
            cleanup();
        }

        let message = "Proceso completado exitosamente";
        if (data && "message" in data && typeof data.message === "string") {
            message = data.message;
        }
        alert(data.message);
    }

    private updatePurchaseIdInputsValue(newValue: string) {
        this.purchaseIdInputTargets.forEach((input) => (input.value = newValue));
    }
}
