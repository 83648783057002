import { Controller, ActionEvent } from "@hotwired/stimulus";
import { displayError, paramProblemUserMessage } from "../helpers/errors";
import { dispatchDataUpdated } from "../helpers/stimulus/events";

const paramTypesMessage =
    "Required event.params = {entityId: number; actionUrl: string;}";

export default class extends Controller {
    static targets = ["label"];

    // targets
    declare readonly labelTarget: HTMLLabelElement;

    async toggle(event: ActionEvent) {
        const target = event.target;
        if (!(target instanceof HTMLInputElement) || target.type !== "checkbox") {
            this._displayError(
                paramProblemUserMessage("control incorrecto"),
                "Event target must be an input[type=checkbox]",
            );
            return;
        }

        if (!event.params) {
            this._displayError(
                paramProblemUserMessage("sin parametros"),
                "No params specified. " + paramTypesMessage,
            );
            return;
        }

        const { entityId, actionUrl } = event.params;
        if (typeof entityId !== "number" || typeof actionUrl !== "string") {
            this._displayError(
                paramProblemUserMessage("tipos inválidos"),
                "Invalid param types. " + paramTypesMessage,
            );
            return;
        }

        let url;
        try {
            url = new URL(actionUrl);
        } catch (error) {
            // might be relative
            try {
                url = new URL(actionUrl, window.location.origin);
            } catch (error) {
                this._displayError(
                    paramProblemUserMessage("mala url"),
                    "actionUrl param is not a valid URL.",
                );
                return;
            }
        }
        const previousChecked = !target.checked;
        const label = this.labelTarget;

        const cleanUp = (() => {
            const originalLabelHTML = label.innerHTML;
            label.textContent = "Cambiando...";
            target.disabled = true;

            return () => {
                target.disabled = false;
                label.innerHTML = originalLabelHTML;
            };
        })();

        let res;
        try {
            res = await window.fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                },
                body: new URLSearchParams({
                    id: entityId.toString(),
                }),
            });
        } catch (error) {
            this._displayError(
                "Se presentó un problema solicitando actualizar el estado.",
                error as Error,
            );
            return;
        } finally {
            cleanUp();
        }
        if (
            !res.ok ||
            !res.headers.get("Content-Type")?.toLowerCase().includes("application/json")
        ) {
            this._displayError(
                "Se presentó un problema con la respuesta del servidor.",
                "Invalid server response.",
            );
            target.checked = previousChecked;
            return;
        }
        const data = await res.json();
        if (!("newState" in data && "newValue" in data && "checked" in data)) {
            this._displayError(
                "Se presentó un problema en los datos recibidos del servidor",
                "Invalid server response data.",
            );
            target.checked = previousChecked;
            return;
        }

        label.textContent = data.newState;
        target.value = data.newValue;
        target.checked = data.checked;

        dispatchDataUpdated(this, {
            entityId,
            prop: "isActive",
            value: data.newValue,
        });
    }

    private _displayError = displayError.bind(this);
}
