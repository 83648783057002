import { ActionEvent, Controller } from "@hotwired/stimulus";

function isActionEvent(e: Event): e is ActionEvent {
    return "params" in e;
}

export default class extends Controller {
    static targets = ["container"];
    declare readonly hasContainerTarget: boolean;
    declare readonly containerTarget: HTMLElement;

    readonly materialIconsClassName = "material-icons";

    get container() {
        if (this.hasContainerTarget) {
            return this.containerTarget;
        }

        return this.element;
    }

    connect() {
        this.container.classList.add(this.materialIconsClassName);
    }

    disconnect(): void {
        this.container.classList.remove(this.materialIconsClassName);
    }

    changeIcon(event: Event) {
        let iconName: string = "";
        if (event instanceof CustomEvent && typeof event.detail.iconName === "string") {
            iconName = event.detail.iconName;
        } else if (isActionEvent(event) && typeof event.params.iconName === "string") {
            iconName = event.params.iconName;
        }
        if (iconName) {
            this.container.textContent = iconName;
        }
    }
}
