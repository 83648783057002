export class DisplayableError extends Error {
    userMessage: string;

    constructor(userMessage: string, ...params: any[]) {
        super(...params);
        this.userMessage = userMessage;
    }
}

export function displayError(userMessage: string, logMessage: any) {
    console.error(logMessage);
    alert(userMessage);
}

export const paramProblemUserMessage = (reason?: string) =>
    `Se presentó un problema con los parámetros de la acción${
        reason ? ` (${reason})` : ""
    }.`;
