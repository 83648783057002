import { Controller } from "@hotwired/stimulus";
import { makeModal } from "../helpers/bs4modal_element";

export default class extends Controller {
    static targets = ["modal"];

    // targets
    declare readonly hasModalTarget: boolean;
    declare readonly modalTarget: HTMLElement;

    $modal: JQuery | null = null;

    connect() {
        if (!this.hasModalTarget) {
            const modal = makeModal();
            modal.setAttribute(
                this.application.schema.targetAttributeForScope(this.identifier),
                "modal",
            );
            this.element.appendChild(modal);
        }

        this.$modal = $(this.modalTarget);
        this._setModalTitle(this.$modal, "Aviso");
    }

    showNotification(event: CustomEvent) {
        const $modal = this.$modal;
        if (!$modal) {
            return;
        }

        this._setModalBody($modal, event.detail?.message || "");
        $modal.modal("show");
    }

    _setModalBody($modal: JQuery, body: string | HTMLElement) {
        const modalBody = $modal.find(".modal-body").get(0);
        if (modalBody) {
            // clear body
            modalBody.innerHTML = "";

            if (typeof body === "string") {
                modalBody.textContent = body;
            } else {
                modalBody.appendChild(body);
            }
        }
    }

    _setModalTitle($modal: JQuery, title: string) {
        const modalTitle = $modal.find(".modal-title").get(0);
        if (modalTitle) {
            modalTitle.textContent = title;
        }
    }
}
