import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["form"];
    declare readonly hasFormTarget: boolean;
    declare readonly formTarget: HTMLFormElement;

    static values = {
        resetOnSuccess: { type: Boolean, default: false },
        formName: { type: String, default: "Formulario" },
    };
    declare readonly resetOnSuccessValue: boolean;
    declare readonly formNameValue: string;

    async submit(event: SubmitEvent) {
        const done = this.processingSubmit(event);

        const form = this.hasFormTarget
            ? this.formTarget
            : (this.element as HTMLFormElement);
        let response;
        try {
            response = await fetch(form.action, {
                method: form.method,
                body: new FormData(form),
            });
        } catch (error: unknown) {
            done();
            this._dispatchNotification(
                "Se presentó un problema en la red que evito realizar la solicitud." +
                    " Disculpe el inconveniente",
            );
            return;
        }

        done();

        if (response.status >= 500) {
            this._dispatchNotification(
                "Se presentó un problema en el servidor." +
                    " Disculpe el inconveniente. Por favor, intente nuevamente mas tarde.",
            );
            return;
        }

        if (response.status >= 400) {
            let jsonData;
            try {
                jsonData = await this._readJson(response);
            } catch {
                return;
            }
            if (response.status === 422) {
                const errorsElement = document.createElement("div");
                errorsElement.textContent = "Errores";

                // funsies
                // on 422, we get a json that aggregates errors on the "name" of the
                // field that failed.
                // So, what we are trying to build here, is a bullet list html that would
                // look kinda like:
                //
                // Errors:
                // - Field1:
                //   - Is empty.
                //   - Has invalid value.
                // - Field2:
                //   - Has invalid value.
                //
                // of course, without a proper templating engine, or stuff setup beforehand,
                // this is the disaster that results of such an idea:
                const errorsContainer = document.createElement("ul");
                for (const [name, errors] of Object.entries(jsonData.errors)) {
                    const fieldErrorsContainer = document.createElement("li");
                    fieldErrorsContainer.textContent =
                        name.charAt(0).toUpperCase() + name.substring(1);

                    // "name" here refers to the underlying field name (the one sent with
                    // the form). So, it's non-ideal to show this to the user.
                    const formElement = form.querySelector(`[name="${name}"]`);
                    if (formElement) {
                        // we'll try and see if there is a label for the control, and use a
                        // clone of that label to show here.
                        // if there is no label (e.g. tom-select redirecting the label to
                        // it's own control), then we'll fallback to using an arbitrary
                        // data- attribute of "label".
                        if (
                            "labels" in formElement &&
                            formElement.labels instanceof NodeList &&
                            formElement.labels.length > 0
                        ) {
                            fieldErrorsContainer.textContent = "";
                            fieldErrorsContainer.appendChild(
                                formElement.labels[0].cloneNode(true),
                            );
                        } else if (
                            "dataset" in formElement &&
                            formElement.dataset instanceof DOMStringMap &&
                            typeof formElement.dataset.label === "string"
                        ) {
                            fieldErrorsContainer.textContent =
                                formElement.dataset.label;
                        }
                    } else if (name === "") {
                        fieldErrorsContainer.textContent = this.formNameValue;
                    }

                    if (Array.isArray(errors)) {
                        const fieldErrors = errors.reduce((container, error) => {
                            const errorContainer = document.createElement("li");
                            errorContainer.textContent = error;

                            container.appendChild(errorContainer);

                            return container;
                        }, document.createElement("ul"));

                        fieldErrorsContainer.appendChild(fieldErrors);
                    }

                    errorsContainer.appendChild(fieldErrorsContainer);
                }

                errorsElement.appendChild(errorsContainer);

                this._dispatchNotification(errorsElement);
            } else {
                // assuming 400
                if (jsonData.message && typeof jsonData.message === "string") {
                    this._dispatchNotification(jsonData.message);
                } else {
                    this._dispatchNotification(
                        "Se presentó un problema desconocido. Disculpe el inconveniente.",
                    );
                }
            }

            return;
        }

        if (response.redirected) {
            const responseUrl = new URL(response.url);
            if (responseUrl.origin !== window.location.origin) {
                this._dispatchNotification(
                    "Se presentó un problema con la respuesta del servidor." +
                        " Por favor, intente nuevamente.",
                );
                return;
            }

            this._dispatchNotification(
                "La sesión ha expirado. Redireccionando a login...",
            );
            window.setTimeout(() => {
                window.location.href = responseUrl.href;
            }, 1500);
            return;
        }

        // all good?
        let jsonData;
        try {
            jsonData = await this._readJson(response);
        } catch {
            return;
        }

        if (jsonData.message) {
            this._dispatchNotification(jsonData.message);
            if (jsonData.redirect) {
                const redirectUrl = jsonData.redirect;
                window.setTimeout(() => {
                    window.location.href = redirectUrl;
                }, 1500);
            }
        }

        if (this.resetOnSuccessValue) {
            form.reset();
        }
    }

    _dispatchNotification(message: string | HTMLElement) {
        this.dispatch("notificationRequested", {
            detail: {
                message,
            },
        });
    }

    async _readJson(response: Response) {
        try {
            return await response.json();
        } catch (error: unknown) {
            this._dispatchNotification(
                "Se presentó un problema leyendo la respuesta del servidor." +
                    " Disculpe el inconveniente",
            );
            throw error;
        }
    }

    protected processingSubmit(event: SubmitEvent) {
        const submitter = event.submitter as HTMLButtonElement;

        submitter.disabled = true;
        const targetInnerHTML = submitter.innerHTML;
        submitter.textContent = "Procesando...";

        return () => {
            submitter.disabled = false;
            submitter.innerHTML = targetInnerHTML;
        };
    }
}
