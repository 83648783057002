export interface OptionSchema {
    text: string;
    value: string;
    dataset?: Record<string, string>;
}

function makeLoadingIndicatorForSelect(element: HTMLSelectElement) {
    let emptyOption: HTMLOptionElement | null = null;
    for (const option of element.options) {
        if (option.value === "") {
            emptyOption = option;
            break;
        }
    }

    // clear select
    element.options.length = 0;

    const loadingOption = new Option("Cargando...", undefined, undefined, true);
    element.options.add(loadingOption);

    return function () {
        if (emptyOption) {
            element.options.add(emptyOption, loadingOption);
        }
        loadingOption.remove();
        const changeEvent = new Event("change");
        element.dispatchEvent(changeEvent);
    };
}

function makeOptionFromSchema(
    data: Record<string, any>,
    schema: OptionSchema,
): HTMLOptionElement {
    const option = new Option(data[schema.text], data[schema.value]);

    if (schema.dataset) {
        Object.entries(schema.dataset).forEach(([datasetKey, dataProp]) => {
            if (data[dataProp]) {
                option.dataset[datasetKey] = data[dataProp];
            }
        });
    }

    return option;
}

export { makeLoadingIndicatorForSelect, makeOptionFromSchema };
