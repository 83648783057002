import { Controller } from "@hotwired/stimulus";

interface AdderState {
    hidden: boolean;
    disabled: boolean;
}

interface DisableableHTMLElement extends HTMLElement {
    disabled: boolean;
}

function isDisableableHTMLElement(obj: HTMLElement): obj is DisableableHTMLElement {
    return "disabled" in obj;
}

export default class extends Controller<HTMLElement> {
    static values = {
        index: { type: Number, default: 0 },
        minItems: { type: Number, default: 1 },
        maxItems: { type: Number, default: 3 },
        currentItemsCount: { type: Number, default: 0 },
    };
    static targets = [
        "item",
        "adder",
        "countDisplay",
        "itemPrototype",
        "removerTemplate",
    ];
    static classes = ["hidden"];

    // values
    declare indexValue: number;
    declare readonly minItemsValue: number;
    declare readonly maxItemsValue: number;
    declare currentItemsCountValue: number;

    // targets
    declare readonly itemTargets: HTMLElement[];

    declare readonly hasAdderTarget: boolean;
    declare readonly adderTargets: HTMLElement[];

    declare readonly hasCountDisplayTarget: boolean;
    declare readonly countDisplayTargets: HTMLElement[];

    declare readonly itemPrototypeTarget: HTMLTemplateElement;
    declare readonly removerTemplateTarget: HTMLTemplateElement;

    // classes
    declare readonly hiddenClass: string;

    itemTargetConnected(element: HTMLElement) {
        if (!this.itemTargetCanConnect()) {
            return element.remove();
        }

        this.updateCurrentItemsCount();
    }

    itemTargetDisconnected() {
        this.updateCurrentItemsCount();
        this.ensureMinItems();
    }

    itemTargetCanConnect() {
        if (this.itemTargets.length > this.maxItemsValue) {
            return false;
        }

        return true;
    }

    adderTargetConnected(element: HTMLElement) {
        this.setElementState(element, {
            hidden: this.currentItemsCountValue >= this.maxItemsValue,
            disabled: this.currentItemsCountValue >= this.maxItemsValue,
        });
    }

    displayCountTargetConnected(element: HTMLElement) {
        this.displayItemCount(element);
    }

    connect() {
        this.ensureMinItems();
    }

    updateCurrentItemsCount() {
        this.currentItemsCountValue = this.itemTargets.length;
    }

    currentItemsCountValueChanged(newValue: number) {
        if (this.hasAdderTarget) {
            const state: AdderState = {
                hidden: newValue >= this.maxItemsValue,
                disabled: newValue >= this.maxItemsValue,
            };

            this.adderTargets.forEach((adder) => {
                this.setElementState(adder, state);
            });
        }
        if (this.hasCountDisplayTarget) {
            this.countDisplayTargets.forEach((countDisplay) => {
                this.displayItemCount(countDisplay);
            });
        }
    }

    setElementState(element: HTMLElement, state: AdderState) {
        // memeing...
        element.classList[state.hidden ? "add" : "remove"](this.hiddenClass);

        if (isDisableableHTMLElement(element)) {
            element.disabled = state.disabled;
        }
    }

    displayItemCount(element: HTMLElement) {
        element.textContent = `${this.currentItemsCountValue} / ${this.maxItemsValue}`;
    }

    appendItem() {
        const templateClone = this.itemPrototypeTarget.content.firstElementChild;
        if (!templateClone) {
            return;
        }

        const prototype = templateClone.cloneNode(true) as HTMLElement;
        prototype.innerHTML = prototype.innerHTML.replace(
            /__index__/g,
            this.indexValue.toString(),
        );
        if (this.currentItemsCountValue >= this.minItemsValue) {
            const buttonTemplateClone =
                this.removerTemplateTarget.content.firstElementChild;
            if (buttonTemplateClone) {
                const buttonPrototype = buttonTemplateClone.cloneNode(true);
                prototype.appendChild(buttonPrototype);
            }
        }
        this.element.insertAdjacentElement("beforeend", prototype);
        this.indexValue++;
    }

    ensureMinItems() {
        if (this.currentItemsCountValue < this.minItemsValue) {
            for (
                let index = this.currentItemsCountValue;
                index < this.minItemsValue;
                index++
            ) {
                this.appendItem();
            }
        }
    }
}
