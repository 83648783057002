import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["input"];
    declare readonly inputTargets: HTMLInputElement[];

    static values = {
        visible: { type: Boolean, default: false },
    };
    declare visibleValue: boolean;

    toggleVisibility(): void {
        let inputType: string;
        if (this.visibleValue) {
            inputType = "text";
        } else {
            inputType = "password";
        }
        this.inputTargets.forEach((i) => (i.type = inputType));
        this.visibleValue = !this.visibleValue;
        this.dispatch("passwordVisibilityChanged", {
            detail: { visible: this.visibleValue },
        });
    }
}
