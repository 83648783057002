import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["button"];
    declare readonly buttonTargets: HTMLButtonElement[];

    enable() {
        this.changeButtonsDisabled(false);
    }

    disable() {
        this.changeButtonsDisabled(true);
    }

    private changeButtonsDisabled(disabled: boolean) {
        this.buttonTargets.forEach((button) => (button.disabled = disabled));
    }
}
