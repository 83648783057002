import DataTable, { Config } from "datatables.net-dt";
import { es } from "../../helpers/datatables-net/language";
import { makeDateTimeRenderer } from "../../helpers/datatables-net/renderers";
import BaseTable from "./base_table";

interface ActionDefinition {
    name?: string;
    template: string;
}
interface ApplicationForm {
    id: number;
    fecha: string;
    nombre: string;
    marcaAutomovil: string;
    modeloAutomovil: string;
    estado: "pendiente" | "rechazado" | "aceptado";
}

function isActionDefinition(val: any): val is ActionDefinition {
    return (
        val &&
        typeof val === "object" &&
        "template" in val &&
        typeof val.template === "string"
    );
}

export default class extends BaseTable {
    static values = {
        ...BaseTable.values,
        dataUrl: String,
        actions: Object,
    };
    declare readonly dataUrlValue: string;
    declare readonly actionsValue: object;

    protected getTableConfig(): Config {
        const textRender = DataTable.render.text();

        const config: Config = {
            ajax: this.dataUrlValue,
            processing: true,
            order: [],
            language: es,
            columns: [
                {
                    title: "Id",
                    data: "id",
                    visible: false,
                    searchable: false,
                    orderable: false,
                },

                {
                    title: "Fecha",
                    data: "fecha",
                    render: makeDateTimeRenderer({
                        locale: "es-CR",
                        options: {
                            dateStyle: "medium",
                            timeStyle: "medium",
                        },
                    }),
                },

                {
                    title: "Nombre",
                    data: "nombre",
                    render: textRender,
                },

                {
                    title: "Marca Automóvil",
                    data: "marcaAutomovil",
                    render: textRender,
                },

                {
                    title: "Modelo Automóvil",
                    data: "modeloAutomovil",
                    render: textRender,
                },

                {
                    title: "Provincia",
                    data: "provincia",
                    render: textRender,
                },

                {
                    title: "Cantón",
                    data: "canton",
                    render: textRender,
                },
            ],
        };

        // parse and make actions, if there are any available.
        // prolly way more simple to use array of objects and use a "name" property,
        // instead of Maps
        const actions = this.parseActions(this.actionsValue);
        if (actions.size > 0) {
            const renderers = Array.from(actions.entries()).reduce(
                (carry, [name, action]) => {
                    const replaceRegex = /__ENTITYID__/g;
                    function makeReplacer(entityId: string = "") {
                        const replacerMap = new Map([["__ENTITYID__", entityId]]);

                        return (match: string) => replacerMap.get(match) || match;
                    }

                    carry.set(name, (entityId: string) => {
                        return action.template.replace(
                            replaceRegex,
                            makeReplacer(entityId),
                        );
                    });

                    return carry;
                },
                new Map(),
            );

            config.columns?.push({
                title: "Acciones",
                data: null,
                orderable: false,
                searchable: false,
                render: (data: ApplicationForm, type: string) => {
                    if (type === "display") {
                        const id = data.id;

                        return Array.from(renderers.entries()).reduce(
                            (carry, [name, renderer]) => {
                                if (name === "delete" && data.estado === "aceptado") {
                                    // skip rendering this action
                                    return carry;
                                }

                                return carry + renderer(id.toString());
                            },
                            "",
                        );
                    }

                    return data;
                },
            });
        }

        return config;
    }

    private parseActions(actions: any): Map<string, ActionDefinition> {
        const actionMap = new Map();
        if (actions && typeof actions === "object" && !Array.isArray(actions)) {
            Object.entries(actions).forEach(([name, action]) => {
                if (isActionDefinition(action)) {
                    if (!("name" in action)) {
                        action.name = name;
                    }
                    actionMap.set(name, action);
                }
            });
        }

        return actionMap;
    }
}
