import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLInputElement> {
    toUpperCase(e: InputEvent) {
        const target = e.target as HTMLInputElement;
        if (target) {
            this.element.value = target.value.toUpperCase();
        }
    }
}
