/// <reference types="webpack/module" />
/* eslint-disable */
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

interface AppWindow extends Window {
    Stimulus: Application;
}

declare let window: AppWindow;

const Stimulus = Application.start();
if (process.env.NODE_ENV === "development") {
    Stimulus.debug = true;
}
const context = require.context("./controllers", true, /\.[jt]s$/);
Stimulus.load(definitionsFromContext(context));

window.Stimulus = Stimulus;

export { Stimulus };
