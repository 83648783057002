import { Controller } from "@hotwired/stimulus";

interface RouteDefinition {
    name: string;
    url: string;
}

export default class extends Controller {
    static values = {
        routes: Object,
    };
    declare readonly routesValue: Record<string, RouteDefinition>;

    redirect(event: CustomEvent) {
        const detail = event.detail as {
            route: string;
            params?: Record<string, string>;
            query?: Record<string, string> | URLSearchParams;
        };

        const { route, params, query } = detail;
        const routeDefinition = this.routesValue[route];
        if (!routeDefinition) {
            console.error(`No route named "${route}" has been defined.`);
            return;
        }

        let url: string = routeDefinition.url;

        if (params && typeof params === "object") {
            const routeParamsMap = new Map(
                Object.entries(params).map(([key, value]) => [`{${key}}`, value]),
            );
            const replaceRegex = new RegExp(
                Array.from(routeParamsMap.keys()).join("|"),
                "g",
            );
            url = url.replace(replaceRegex, (m) =>
                routeParamsMap.has(m) ? routeParamsMap.get(m) || "" : "",
            );
        }

        if (query && typeof query === "object") {
            let searchParams = query;
            if (!(searchParams instanceof URLSearchParams)) {
                searchParams = new URLSearchParams(Object.entries(query));
            }

            url += "?" + searchParams.toString();
        }

        window.location.assign(url);
    }
}
