import { paramProblemUserMessage } from "../errors";

interface ActionUrlParam {
    actionUrl: URL;
}

interface EntityIdParam {
    entityId: number;
}

export type EntityRouteActionParams = ActionUrlParam & EntityIdParam;

export class BadParamsError extends Error {
    userMessage: string;

    constructor(userMessage: string, ...params: any[]) {
        super(...params);
        this.userMessage = userMessage;
    }
}

const paramTypesMessage =
    "Required event.params = {entityId: number; actionUrl: string;}";

export function entityRouteActionParams(params: any): EntityRouteActionParams {
    if (!params) {
        throw new BadParamsError(
            paramProblemUserMessage("sin parametros"),
            "No params specified. " + paramTypesMessage,
        );
    }

    const { entityId, actionUrl } = params;
    if (typeof entityId !== "number" || typeof actionUrl !== "string") {
        throw new BadParamsError(
            paramProblemUserMessage("tipos inválidos"),
            "Invalid param types. " + paramTypesMessage,
        );
    }

    let url: URL;
    try {
        url = new URL(actionUrl);
    } catch (error) {
        // might be relative
        try {
            url = new URL(actionUrl, window.location.origin);
        } catch (error) {
            throw new BadParamsError(
                paramProblemUserMessage("mala url"),
                "actionUrl param is not a valid URL.",
            );
        }
    }

    return {
        entityId,
        actionUrl: url,
    };
}
