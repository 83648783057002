import { Controller } from "@hotwired/stimulus";
import { makeLoadingIndicatorForSelect } from "../helpers/select_utils";

export default class extends Controller<HTMLSelectElement> {
    static values = {
        targetId: String,
        fetchUrl: String,
        schema: Object,
    };

    declare readonly targetIdValue: string;
    declare readonly fetchUrlValue: string;
    declare readonly schemaValue: Record<string, any>;

    load(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target.id === this.targetIdValue) {
            const loadingCleanup = makeLoadingIndicatorForSelect(this.element);
            const url = [this.fetchUrlValue, target.value].join("/");
            const schema = this.schemaValue;
            fetch(url)
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    const items = data.data as Record<string, any>[];
                    items.forEach((item) => {
                        const option = new Option(
                            item[schema.text],
                            item[schema.value],
                        );
                        if (schema.dataset as Record<string, string>) {
                            Object.entries(schema.dataset).forEach(
                                ([dataKey, itemProp]) => {
                                    option.dataset[dataKey] = item[itemProp as string];
                                },
                            );
                        }
                        this.element.options.add(option);
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.element.options.add(
                        new Option(
                            "Se presentó un error obteniendo los datos del servidor",
                            undefined,
                            undefined,
                            true,
                        ),
                    );
                })
                .finally(() => {
                    loadingCleanup();
                });
        }
    }
}
