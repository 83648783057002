import he from "he";

export interface Image {
    caption: string;
    src: string;
}

export function makeImageRenderer() {
    return function imageRenderer(data: Image | Image[], type: string, row: any) {
        if (type === "display") {
            if (!Array.isArray(data)) {
                data = [data];
            }

            return data.reduce((html, image) => {
                return (
                    html +
                    `<img class="image-preview" loading="lazy"
                    src="${he.encode(image.src)}" alt="${he.encode(image.caption)}">`
                );
            }, "");
        }

        return data;
    };
}

export function makeIsActiveRenderer(options: {} = {}) {
    let toggleUrl = "";
    if ("toggleUrl" in options && typeof options.toggleUrl === "string") {
        let url;
        try {
            url = new URL(options.toggleUrl);
        } catch (error) {
            // pass
        }
        if (url) {
            toggleUrl = url.href;
        }
    }
    let template: string;
    if ("template" in options && typeof options.template === "string") {
        template = options.template;
    } else {
        template = `<div>
            <label>
                <div class="switch-label">__LABEL__</div>
                <input class="form-control state-toggle" type="checkbox"
                    name="estaActivo" value="__VALUE__" __CHECKED__>
            </label>
        </div>`;
    }
    const templateReplaceRegex =
        /__URL__|__LABEL__|__VALUE__|__CHECKED__|__ENTITYID__/g;
    const makeReplacer = (
        label: string = "",
        value: string = "",
        checked: string = "",
        entityId: string = "",
    ) => {
        const replacerMap = new Map<string, string>([
            ["__URL__", toggleUrl],
            ["__LABEL__", label],
            ["__VALUE__", value],
            ["__CHECKED__", checked],
            ["__ENTITYID__", entityId],
        ]);
        return (m: string) => {
            const replacement = replacerMap.get(m);
            if (typeof replacement === "string") {
                return replacement;
            }
            return m;
        };
    };

    return function (data: boolean, type: string, row: any) {
        if (type === "display") {
            const description = data ? "Activo" : "Inactivo";
            let id = 0;
            if ("id" in row) {
                switch (typeof row.id) {
                    case "string":
                        const rowId = Number.parseInt(row.id);
                        if (!Number.isNaN(rowId)) {
                            id = rowId;
                        }
                        break;
                    case "number":
                        id = row.id;
                        break;
                }
            }

            return template.replace(
                templateReplaceRegex,
                makeReplacer(
                    description,
                    data.toString(),
                    data ? "checked" : "",
                    id.toString(),
                ),
            );
        }

        return data;
    };
}

function parseId(id: unknown): number {
    if (typeof id === "string") {
        const rowId = Number.parseInt(id);
        if (!Number.isNaN(rowId)) {
            return rowId;
        }
    } else if (typeof id === "number") {
        return id;
    }

    return 0;
}

export function makeIsInSpykaRenderer(options: {} = {}) {
    let trueTemplate = `<span class="text-success">&#10004;</span> Registrado en Spyka.`;
    if ("trueTemplate" in options && typeof options.trueTemplate === "string") {
        trueTemplate = options.trueTemplate;
    }
    let falseTemplate = "";
    if ("falseTemplate" in options && typeof options.falseTemplate === "string") {
        falseTemplate = options.falseTemplate;
    }

    const templateReplaceRegex = /__ENTITYID__/g;
    const makeReplacer = (entityId: string = "") => {
        const replacerMap = new Map<string, string>([["__ENTITYID__", entityId]]);
        return (m: string) => replacerMap.get(m) || m;
    };

    return function (data: boolean, type: string, row: any) {
        if (type === "display") {
            let id = 0;
            if ("id" in row) {
                id = parseId(row.id);
            }

            const replacer = makeReplacer(id.toString());
            const template = data ? trueTemplate : falseTemplate;
            return template.replace(templateReplaceRegex, replacer);
        }

        return data;
    };
}

export function makeActionsRenderer(actions: {} = {}) {
    const renderers = Object.entries<object>(actions).map(([action, options]) => {
        let template: string;
        if ("template" in options && typeof options.template === "string") {
            template = options.template;
        } else {
            return () => {}; // noop
        }

        const replaceRegex = /__ENTITYID__/g;
        function makeReplacer(entityId: string = "") {
            const replacerMap = new Map([["__ENTITYID__", entityId]]);

            return (m: string) => replacerMap.get(m) || m;
        }

        return (entityId: string) => {
            return template.replace(replaceRegex, makeReplacer(entityId));
        };
    });

    return function (data: null, type: string, row: any) {
        if (type === "display") {
            let id = 0;
            if ("id" in row) {
                id = parseId(row["id"]);
            }

            return renderers.reduce((s, r) => s + r(id.toString()), "");
        }

        return data;
    };
}

export function makeDateTimeRenderer(options: {} = {}) {
    let locale: string | undefined = undefined;
    if ("locale" in options && typeof options.locale === "string") {
        locale = options.locale;
    }
    let dateTimeFormatOptions: Intl.DateTimeFormatOptions | undefined = undefined;
    if (
        "options" in options &&
        options.options &&
        typeof options.options === "object"
    ) {
        dateTimeFormatOptions = options.options;
    }

    const dateTimeFormatter = new Intl.DateTimeFormat(locale, dateTimeFormatOptions);

    return function (data: string, type: string, row: any) {
        if (type === "display") {
            // data should be an rfc3339 or iso8601 valid date time format string
            const datetime = new Date(data);

            return dateTimeFormatter.format(datetime);
        }

        return data;
    };
}

interface LocaleOptions {
    locale?: string;
}

type NumberParser = (s: string) => number;

type NumberFormatOptions = LocaleOptions & {
    formatterOptions?: Intl.NumberFormatOptions;
    parser?: "int" | "float" | NumberParser;
};

export function makeNumberRenderer(options: NumberFormatOptions = {}) {
    const numberFormatter = new Intl.NumberFormat(
        options.locale,
        options.formatterOptions,
    );
    let parse: NumberParser;
    if (typeof options.parser === "string" && options.parser === "int") {
        parse = Number.parseInt;
    } else if (typeof options.parser === "function") {
        parse = options.parser;
    } else {
        parse = Number.parseFloat;
    }

    return function (data: string, type: string) {
        if (type === "display") {
            const total = parse(data);
            if (Number.isNaN(total)) {
                return "No disponible";
            }

            return numberFormatter.format(total);
        }
        if (type === "sort") {
            return parse(data);
        }

        return data;
    };
}

export const defaultNumberFormatRenderer = makeNumberRenderer({
    locale: "es-CR",
    formatterOptions: {
        maximumFractionDigits: 2,
    },
});
