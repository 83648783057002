import { ActionEvent, Controller } from "@hotwired/stimulus";
import { makeStimulusModal } from "../helpers/bs4modal_element";
import { parseUrl } from "../helpers/fetch_utils";

interface HelpParams {
    url: URL;
}

export default class extends Controller {
    static targets = ["modal"];
    declare readonly hasModalTarget: boolean;
    declare readonly modalTarget: HTMLElement;

    static classes = ["loader"];
    declare readonly loaderClasses: string[];

    declare modal: HTMLElement;

    connect() {
        if (!this.hasModalTarget) {
            const modal = makeStimulusModal(this);
            const modalTitle = modal.querySelector(".modal-title");
            if (modalTitle) {
                modalTitle.textContent = "Información";
            }
            document.body.appendChild(modal);

            this.modal = modal;
        } else {
            this.modal = this.modalTarget;
        }
    }

    async show(event: ActionEvent) {
        const params = this.parseParams(event);

        const body = this.getModalBody();
        body.innerHTML = "";
        body.classList.add(...this.loaderClasses);
        this.showModal();

        let info;
        try {
            info = await this.fetchHelp(params.url);
        } catch (error) {
            console.log(error);
            info =
                "Se presentó un problema en el servidor al obtener la ayuda." +
                " Disculpe el inconveniente";
        }

        body.classList.remove(...this.loaderClasses);
        const p = document.createElement("p");
        p.innerHTML = info.replace(/<script>|<\/script>|\n/gi, (match) => {
            // remove script tags
            if (match.includes("script")) {
                return "";
            }

            // any other match is a line break, so replace them with <br>
            return "<br>";
        });
        body.appendChild(p);
    }

    private parseParams(event: ActionEvent): HelpParams {
        if (!event.params || typeof event.params.url !== "string") {
            throw new Error('Required "url" param is missing');
        }

        return {
            url: parseUrl(event.params.url),
        };
    }

    private showModal(): void {
        $(this.modal).modal("show");
    }

    private getModalBody(): HTMLElement {
        const body = this.modal.querySelector<HTMLElement>(".modal-body");
        if (!body) {
            return this.modal;
        }

        return body;
    }

    private async fetchHelp(url: URL): Promise<string> {
        const response = await fetch(url);

        if (response.status >= 500) {
            response.text();
            throw new Error("Error interno del servidor.");
        }

        let message = "Error: se presentó un problema obteniendo la ayuda.";
        const json = await response.json();

        // invalid json
        if (!json || typeof json !== "object") {
            return message;
        }

        // error in json
        if (json.error) {
            if (typeof json.msg === "string") {
                message = json.msg;
            } else if (typeof json.message === "string") {
                message = json.message;
            }

            return message;
        }

        // help message
        if (
            json.data &&
            typeof json.data === "object" &&
            typeof json.data.info === "string"
        ) {
            return json.data.info;
        }

        return message;
    }
}
