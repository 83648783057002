import { DisplayableError } from "./errors";

export async function parseJsonResponse(res: Response) {
    if (!res.ok) {
        const defaultMessage = "Se presentó un problema con la respuesta del servidor.";
        if (
            !res.headers
                .get("Content-Type")
                ?.toLocaleLowerCase()
                .includes("application/json")
        ) {
            throw new DisplayableError(defaultMessage, "Invalid server response.");
        }

        const data = await res.json();
        let responseMessage = defaultMessage;
        const messageProp = ["msg", "message", "statusMessage"].find(
            (prop) => prop in data && typeof data[prop] === "string",
        );
        if (messageProp) {
            responseMessage = data[messageProp];
        }

        throw new DisplayableError(responseMessage, "Invalid operation.");
    }
    try {
        return await res.json();
    } catch (error) {
        throw new DisplayableError(
            "Se presentó un problema con la respuesta del servidor.",
            "Non-parseable json response",
            { cause: error },
        );
    }
}

export function parseUrl(url: string) {
    try {
        return new URL(url);
    } catch {
        return new URL(url, window.location.origin);
    }
}
