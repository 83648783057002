import { Controller } from "@hotwired/stimulus";

export interface DataUpdatedDetail {
    entityId: number;
    prop: string;
    value: any;
}

export function dispatchDataUpdated(
    controller: Controller,
    detail: DataUpdatedDetail,
    prefix?: string,
) {
    if (!prefix) {
        prefix = "";
    }
    controller.dispatch("dataUpdated", {
        prefix,
        detail,
    });
}

export interface EntityDeletedDetail {
    entityId: number;
}

export function dispatchEntityDeleted(
    controller: Controller,
    detail: EntityDeletedDetail,
    prefix?: string,
) {
    if (!prefix) {
        prefix = "";
    }
    controller.dispatch("entityDeleted", {
        prefix,
        detail,
    });
}
