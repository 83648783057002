import { ActionEvent, Controller } from "@hotwired/stimulus";

function isEventTargetWithStringValue(e: any): e is { value: string } & EventTarget {
    return "value" in e && typeof e.value === "string";
}

// mirrors a value on input or change
export default class extends Controller {
    static targets = ["mirror"];
    declare readonly mirrorTargets: HTMLInputElement[];

    mirrorValue(event: ActionEvent): void {
        const target = event.target;
        if (isEventTargetWithStringValue(target)) {
            this.mirrorTargets.forEach((mirror) => {
                mirror.value = target.value;
            });
        }
    }
}
