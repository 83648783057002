import { Controller } from "@hotwired/stimulus";
import DataTable, { Api, Config } from "datatables.net-dt";

export default class extends Controller<HTMLElement | HTMLTableElement> {
    static targets = ["table"];
    declare readonly hasTableTarget: boolean;
    declare readonly tableTarget: HTMLTableElement;

    tableInitialized = false;
    $dtApi?: Api<any>;

    get table(): HTMLTableElement {
        if (this.hasTableTarget) {
            return this.tableTarget;
        }
        if (this.element instanceof HTMLTableElement) {
            return this.element;
        }

        throw new Error(
            "No valid table element found to attach to." +
                " No table target found" +
                " and this.element is not an HTMLTableElement.",
        );
    }

    connect(): void {
        this.tableInitialized = false;
        const table = this.table;

        if (DataTable.isDataTable(table)) {
            this.tableInitialized = true;
        }

        if (!this.tableInitialized) {
            const tableConfig = this.getTableConfig();
            this.$dtApi = new DataTable(table, tableConfig);
        }
    }

    disconnect(): void {
        if (this.tableInitialized) {
            this.$dtApi?.destroy();
        }
    }

    ajaxReload() {
        if (this.$dtApi && this.$dtApi.ajax) {
            this.$dtApi.ajax.reload(undefined, false);
        }
    }

    protected getTableConfig(): Config {
        return {};
    }
}
