import { ActionEvent, Controller } from "@hotwired/stimulus";
import { DisplayableError, displayError } from "../helpers/errors";
import { parseJsonResponse, parseUrl } from "../helpers/fetch_utils";
import { dispatchEntityDeleted } from "../helpers/stimulus/events";

export default class extends Controller {
    async delete(event: ActionEvent) {
        const url = this._parseDeleteParams(event.params);
        if (!url) {
            return;
        }

        async function confirm(message: string): Promise<boolean> {
            return window.confirm(message);
        }

        const target = event.target as HTMLElement;
        const confirmMessage =
            "¿Desea realmente eliminar el registro? Esta acción no se puede revertir.";
        const confirmed = await confirm(confirmMessage);
        if (!confirmed) {
            return;
        }

        const initialize = () => {
            let reEnableTarget: () => void;
            if ("disabled" in target) {
                reEnableTarget = () => (target.disabled = false);
                target.disabled = true;
            } else {
                reEnableTarget = () => {}; // noop
            }
            const removingElement = document.createElement("div");
            removingElement.textContent = "Removiendo...";

            target.appendChild(removingElement);

            return () => {
                reEnableTarget();
                removingElement.remove();
            };
        };
        const cleanUp = initialize();
        let res;
        try {
            res = await window.fetch(url, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                },
            });
        } catch (error) {
            this._displayError(
                "Se presentó un problema solicitando la eliminación del registro.",
                error,
            );
            cleanUp();
            return;
        }

        let data;
        try {
            data = await parseJsonResponse(res);
        } catch (error) {
            let userMessage;
            if (error instanceof DisplayableError) {
                userMessage = error.userMessage;
            } else {
                userMessage = "Se presentó un problema inesperado.";
            }
            this._displayError(userMessage, error);
            cleanUp();
            return;
        }
        let id = 0;
        if ("deletedId" in data && typeof data.deletedId === "number") {
            id = data.deletedId;
        }

        dispatchEntityDeleted(this, {
            entityId: id,
        });
    }

    _parseDeleteParams(params: any): URL | null {
        if (!params) {
            return null;
        }

        if (!("requestUrl" in params)) {
            return null;
        }

        const url = params.requestUrl;
        try {
            return parseUrl(url);
        } catch (error) {
            this._displayError(
                "Se presentó un problema al solicitar la eliminación del registro.",
                error,
            );
            return null;
        }
    }

    private _displayError = displayError.bind(this);
}
