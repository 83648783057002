import { Api, Config } from "datatables.net-dt";
import BaseTable from "./base_table";
import { es } from "../../helpers/datatables-net/language";
import "datatables.net-select-dt";
import {
    makeDateTimeRenderer,
    makeNumberRenderer,
} from "../../helpers/datatables-net/renderers";

const numberFormatRenderer = makeNumberRenderer({
    locale: "es-CR",
    formatterOptions: {
        maximumFractionDigits: 2,
    },
});

export default class extends BaseTable {
    static values = {
        ...BaseTable.values,
        dataUrl: String,

        rowSelectedEvent: { type: String, default: "rowSelected" },
        rowDeselectedEvent: { type: String, default: "rowDeselected" },
    };
    declare readonly dataUrlValue: string;
    declare readonly rowSelectedEventValue: string;
    declare readonly rowDeselectedEventValue: string;

    connect(): void {
        super.connect();

        if (this.$dtApi) {
            this.$dtApi.on("select", this.selectHandler);
            this.$dtApi.on("deselect", this.deselectHandler);
        }
    }

    // prop functions to ensure events are registered only once
    private selectHandler = (
        e: Event,
        dt: Api<any>,
        type: string,
        indexes: number[],
    ) => {
        this.handleSelection("select", indexes);
    };

    private deselectHandler = (
        e: Event,
        dt: Api<any>,
        type: string,
        indexes: number[],
    ) => {
        this.handleSelection("deselect", indexes);
    };

    private handleSelection(event: "select" | "deselect", indexes: number[]) {
        const data = this.getRowData(indexes[0]);
        if (data !== undefined) {
            this.dispatch(
                event === "select"
                    ? this.rowSelectedEventValue
                    : this.rowDeselectedEventValue,
                {
                    detail: data,
                    prefix: "",
                },
            );
        }
    }

    private getRowData(index: number): any {
        if (this.$dtApi) {
            return this.$dtApi.row(index).data();
        }

        return undefined;
    }

    protected getTableConfig(): Config {
        const config = {
            ajax: this.dataUrlValue,
            processsing: true,
            language: es,
            columns: [
                { data: "id", visible: false },
                { data: "consecutivo", title: "Consecutivo" },
                {
                    data: "fecha",
                    title: "Fecha",
                    render: makeDateTimeRenderer({ locale: "es-CR" }),
                },
                { data: "emisorNombre", title: "Emisor" },
                {
                    data: "total",
                    title: "Total",
                    render: numberFormatRenderer,
                },
            ],
            select: {
                style: "single",
                items: "row",
                info: false,
            },
        };

        return config;
    }
}
