import IconController from "./icon_controller";

export default class extends IconController {
    readonly visibleIconName = "visibility";
    readonly invisibleIconName = "visibility_off";

    changeIcon(event: Event): void {
        if (event instanceof CustomEvent) {
            if (typeof event.detail.visible === "boolean") {
                this.container.textContent = event.detail.visible
                    ? this.visibleIconName
                    : this.invisibleIconName;
            }
        }
    }
}
