import ItemController from "./item_controller";
import { ActionEvent } from "@hotwired/stimulus";
import {
    makeLoadingIndicatorForSelect,
    makeOptionFromSchema,
    OptionSchema,
} from "../helpers/select_utils";

type VehicleTargetNames = "brand" | "model" | "generation";

interface LoadSelectDataParams {
    target: VehicleTargetNames;
    url: string;
    optionSchema: OptionSchema;
}

export default class extends ItemController<HTMLElement> {
    static targets = ["brand", "model", "generation"];

    // targets
    declare readonly brandTarget: HTMLSelectElement;
    declare readonly modelTarget: HTMLSelectElement;
    declare readonly generationTarget: HTMLSelectElement;

    loadSelectData(event: ActionEvent) {
        const params = event.params as LoadSelectDataParams;

        const targetName = String(params.target) as VehicleTargetNames;
        const target = this.getTargetFromString(targetName);
        if (target === null) {
            console.log(`Target for name: "${targetName}" not found.`);
            return;
        }

        const baseUrl = String(params.url).trim();
        if (baseUrl === "") {
            console.log("No url param provided");
            return;
        }

        const loadingCleanup = makeLoadingIndicatorForSelect(target);
        const schema = params.optionSchema as OptionSchema;
        const filter = (event.target as HTMLSelectElement).value;
        const url = [baseUrl, filter].join("/");

        fetch(url)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                const items = data.data as Record<string, any>[];
                items.forEach((item) => {
                    const option = makeOptionFromSchema(item, schema);
                    target.options.add(option);
                });
            })
            .catch((error) => {
                console.log(error);
                target.options.add(
                    new Option(
                        "Se presentó un problema al obtener los datos...",
                        undefined,
                        undefined,
                        true,
                    ),
                );
            })
            .finally(() => {
                loadingCleanup();
            });
    }

    getTargetFromString(targetName: VehicleTargetNames): HTMLSelectElement | null {
        switch (targetName) {
            case "brand":
                return this.brandTarget;
            case "model":
                return this.modelTarget;
            case "generation":
                return this.generationTarget;
            default:
                return null;
        }
    }
}
